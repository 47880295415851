button.enterprise-star {
  cursor: pointer;
  margin-left: 6px;

  i:before {
    transition: all .3s;
    margin-top: -3px;
  }

  &:hover {
    i:before {
      transform: scale(1.5);
    }
  }
}