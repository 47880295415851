@-webkit-keyframes load7 {
  0%, 80%, 100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }

  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

@keyframes load7 {
  0%, 80%, 100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }

  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

.loader {
  color: #fff;
  font-size: 10px;
  margin: -2.5em auto 20px;
  position: relative;
  transform: translateZ(0);
  animation-delay: -0.16s;

  &, &:before, &:after {
    border-radius: 50%;
    width: 1em;
    height: 1em;
    animation: load7 1.8s infinite ease-in-out;
    animation-fill-mode: both;
  }

  &:before, &:after {
    content: '';
    position: absolute;
    top: 0;
  }

  &:before {
    left: -1.5em;
    animation-delay: -0.32s;
  }

  &:after {
    animation-delay: 0.32s;
    left: 1.5em;
  }
}

.augur-form {
  .form-check {
    margin-top: 2em;
  }
}