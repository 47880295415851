@import "../base/global";

.icon-addons {
  &.plan-bas {
    color: $plan-basic;
  }

  &.plan-pro {
    color: $plan-professional;
  }

  &.plan-pre {
    color: $plan-premium;
  }

  &.plan-cor {
    color: $plan-corporate;
  }

  &.plan-bas &:before {
    color: $plan-basic;
  }

  &.plan-pro &:before {
    color: $plan-professional;
  }

  &.plan-pre &:before {
    color: $plan-premium;
  }

  &.plan-cor &:before {
    color: $plan-corporate;
  }
}
